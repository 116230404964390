import React, { useEffect } from "react";
import logo from "assets/images/LogoTransparency.png";
import AuthLeftSide from "../AuthLeftSide/AuthLeftSide";
import {
  LockOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import {
  DataRequestAction,
  setInitUrl,
} from "../../../redux/actions/CommonHttp";
import { Typography, Form, Input, Button, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
const { Title } = Typography;
const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  console.log("====params", params.query !== "", params);

  const authProps = useSelector((state) => state.auth);
  const { loginSuccess, loginStart, loginFail } = authProps;

  console.log("====loginSuccess", authProps);
  const onFinish = (values) => {
    values.email = values.email.toLowerCase();
    dispatch(
      DataRequestAction(
        "POST",
        "login",
        "Login",
        values,
        "LoginSpinner",
        "",
        "",
        "",
        "Email or Password is inCorrect"
      )
    );
  };
  if (loginFail) {
    message.error("Email or Password is incorrect");
    dispatch({
      type: "update_auth",
    });
  }
  if (loginSuccess) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    dispatch({
      type: "update_auth",
    });
    if (userInfo.role === "student") {
      dispatch(setInitUrl("/dashboard"));
      history.push("/dashboard");
    }
  }
  useEffect(() => {
    if (params && params.query) {
      if (params.query !== "") {
        dispatch(
          DataRequestAction(
            "PUT",
            "changeUserStatusOnLogin",
            "EditRecord",
            { guid: params.query },
            "",
            "",
            "changeUserStatusOnLogin"
          )
        );
      }
    }
  }, []);

  return (
    <AuthLeftSide>
      <div className=" gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center gx-h-100 ">
        <div className="gx-text-center">
          <img src={logo} alt="logo_image" />
          <Title
            className="gx-font-sans-bold gx-text-white gx-mb-0 gx-mt-5"
            level={2}
          >
            {intlPlaceholder("logIn.pageTitle")}
          </Title>
          <Title
            className="gx-font-sans-regular gx-text-white gx-mt-0"
            level={5}
          >
            {intlPlaceholder("logIn.content")}
          </Title>
        </div>
        <div className=" gx-d-flex  gx-align-items-center ">
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form-inputs"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label={
                <span className="gx-text-white">
                  {intlPlaceholder("logIn.emailLabel")}
                </span>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: intlPlaceholder("logIn.email_message"),
                },
                {
                  type: "email",
                  message: intlPlaceholder("logIn.email_message"),
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder={intlPlaceholder("logIn.email")}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="gx-text-white">
                  {intlPlaceholder("logIn.passwordLabel")}
                </span>
              }
              name="password"
              className="gx-mb-1 "
              rules={[
                {
                  required: true,
                  message: intlPlaceholder("logIn.password_message"),
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={intlPlaceholder("logIn.password")}
                postfix={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <div className="gx-d-flex gx-justify-content-end">
              <span
                className="gx-link gx-pointer gx-text-white gx-font-sans-medium gx-pt-1"
                onClick={() => history.push("/forgetPassword")}
                style={{ color: "#4C5755", fontSize: "16px" }}
              >
                {intlPlaceholder("logIn.forgetPassword")}
              </span>
            </div>

            <Button
              htmlType="submit"
              type="primary"
              style={{ height: "50px", fontSize: "18px" }}
              loading={loginStart}
              disabled={loginStart}
              className="gx-w-100 gx-font-sans-bold gx-mt-4 gx-gradient-button gx-py-0"
            >
              {intlPlaceholder("logIn.btn")}
            </Button>
          </Form>
        </div>
      </div>
    </AuthLeftSide>
  );
};

export default Index;
