import React, { useState } from "react";
import FormModal from "components/Modal/FormModal";
import ReservationForm from "./ReservationForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddReservation = () => {
  const [form] = Form.useForm();
  const { ADD_Reservation_MODAL } = useSelector((state) => state.CurdR);
  const [id, setId] = useState("");


  console.log("====id",id)
  if (!ADD_Reservation_MODAL && id) {
    setId("");
  }
  return (
    <FormModal
      addButtonName={intlPlaceholder("reservation.add")}
      modalTitle={intlPlaceholder("reservation.TitleChange")}
      addApiName="addMeetingRequest"
      ModalName="ADD_Reservation_MODAL"
      layout="vertical"
      visible={ADD_Reservation_MODAL}
      width={700}
      recordName={"reservationList"}
      form={form}
      extraFieldName={"reservationWith"}
      extraFieldValue={id}
    >
      <ReservationForm setId={setId} />
    </FormModal>
  );
};
export default AddReservation;
