import { Col, Row } from "antd";
import React from "react";
import ChartCard from "components/ChartCard";
import { ImBooks } from "react-icons/im";
import PathYellow from "assets/images/Path.png";
import PathBlue from "assets/images/Path38118.png";
import PathGreen from "assets/images/Path38119.png";


const ChartCourse = ({ cardRecord }) => {
  console.log("====cardRecord===", cardRecord);
  return (
    <div>
      <Row className="gx-mb-2 gx-h-50">
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
             count={(cardRecord && cardRecord.length && cardRecord[0].totalCourses) || 0}
            bgColor="#EFD7FB"
            title={"allCourses.Card1Title"}
            icon={<ImBooks size={35} fill="#A771E9" />}
            graph={<img src={PathBlue} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
           count={(cardRecord && cardRecord.length && cardRecord[0].onlineCourses) || 0}
           
            bgColor="#D4F4E8"
            title={"allCourses.Card2Title"}
            icon={<ImBooks size={35} fill="#3DBC78" />}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            count={(cardRecord && cardRecord.length && cardRecord[0].offlineCourses) || 0}
            
            bgColor="#FADADC"
            title={"allCourses.Card3Title"}
            icon={<ImBooks size={35} fill="#F6422E" />}
            graph={<img src={PathYellow} alt="graph" />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChartCourse;
