import React from "react";
import SelectFilter from "../../../components/Filters/SelectFilter";
import DateRangPicker from "../../../components/Filters/DateRangPicker";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Typography } from "antd";
const { Title } = Typography;
const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-justify-content-between gx-mb-3 "
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold ">
        {intlPlaceholder("assignment.pageTitle")}
      </Title>
      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
        <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="getAssignmentsForStudent"
          recordName="assignmentList"
          requestType="FetchTableRecord"
          width="230px"
        />
         <SelectFilter
          apiName="getAssignmentsForStudent"
          recordName="assignmentList"
          requestType="FetchTableRecord"
          placeholder={"status"}
          searchKey="status"
          width={"150px"}
          isShowingAll={false}
          options={[
            { status: "open", label: "Open" },
            { status: "close", label: "Close" },
          ]}
          ItemValue="status"
          label="label"
        />

       

       
      </div>
    </div>
  );
};

export default TableHeader;
