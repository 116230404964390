import React, { useState, useEffect } from "react";
import SalesReportList from "components/Table/PageNationTable";
import ActionDropdown from "components/Table/Actions";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import moment from "moment";
import { DataGetAction } from "redux/actions/CommonHttp";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
const SalesReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterValue, setFilterValue] = useState({});
  const fetchCourse = () => {
    dispatch(
      DataGetAction(
        "getAllCourses",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "courseTitle"
      )
    );
  };
  useEffect(fetchCourse, []);

  const renderActions = (record) => {
    return (
      <div
        style={{ gap: "7px" }}
        className="gx-d-flex gx-flex-column popoverContent"
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push({
              pathname: "/viewSalesReport",
              state: { _id: record._id, studentId: record.studentId },
            })
          }
        >
          View
        </span>
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("FinancialSummary.transcetionId"),
      render: (record) => (
        <span className="gx-text-capitalize ">
          {record.orderId || "----"}
        </span>
      ),
      key: "transcetionId",
    },
   
    {
      title: intlPlaceholder("salesReport.courseName"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.courseName}</span>
      ),
      key: "courseName",
    },
    {
      title: intlPlaceholder("salesReport.coursePrice"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.coursePrice}</span>
      ),
      key: "coursePrice",
    },
    {
      title: intlPlaceholder("salesReport.paidAmount"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.coursePrice}</span>
      ),
      key: "paidAmount",
    },
    {
      title: intlPlaceholder("salesReport.remainingBalance"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.balance}</span>
      ),
      key: "remainingBalance",
    },
    {
      title: intlPlaceholder("salesReport.purchaseDate"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.createdAt).format("MM-DD-YYYY")}
        </span>
      ),
      key: "purchaseDate",
    },

    {
      title: "",
      render: (record) => (
        <ActionDropdown>{renderActions(record)}</ActionDropdown>
      ),
      key: "action",
      width: "50px",
    },
  ];

  return (
    <Layout>
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <SalesReportList
          apiName="getStudentPurchasedReceipt"
          recordName="salesReportList"
          requestType="FetchRecord"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
    </Layout>
  );
};

export default SalesReport;
