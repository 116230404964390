import React, { useEffect, useState } from "react";
import { Select, Form, DatePicker, Input, Spin, message } from "antd";
import { DataGetAction } from "redux/actions/CommonHttp";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
const { TextArea } = Input;

const ReservationForm = ({ setId }) => {
  const dispatch = useDispatch();

  const curdProps = useSelector((state) => state.CurdR);
  const {
    timeList,
    studentSuccess,
    fetchSuccess,
    fetchDataMessage,
    instructorLists,
    ADD_Reservation_MODAL,
    timeListTrue,
  } = curdProps;
  const [fetchData, setFetchData] = useState("");
  console.log("====createdBy", ADD_Reservation_MODAL);
  const Role = [
    {
      value: "admin",
      label: "Admin ",
    },
    {
      value: "instructor",
      label: "Instructor",
    },
  ];

  const createdBy = timeList && timeList.createdBy;
  const TimeDetails = timeList && timeList.meetingTime;

  const onSelect = (e) => {
    setFetchData(e);
  };
  let instectorId = null;
  const setinstectorId = (value) => {
    instectorId = value;
  };
  console.log("value", instectorId);
  const onChange = (date) => {
    if (fetchData === "instructor") {
      if (date) {
        dispatch(
          DataGetAction(
            "getSlotForInstructor",
            "FetchDataArray",
            { date: date._d, instructorId: instectorId },
            "StudentSpinner",
            "",
            "timeList"
          )
        );
      }
    } else {
      if (date) {
        dispatch(
          DataGetAction(
            "getAdminMeetingSlot",
            "FetchDataArray",
            { date: date._d },
            "StudentSpinner",
            "",
            "timeList"
          )
        );
      }
    }
    instectorId = null;
  };
  if (timeListTrue) {
    setId(createdBy);
  }
  if (fetchSuccess) {
    if (timeList || (timeList && timeList.length === 0)) {
      message.info(fetchDataMessage.message);
    }
    setId(createdBy);

    dispatch({
      type: "ResetAll_State",
    });
  }
  useEffect(() => {
    setFetchData("");
  }, [ADD_Reservation_MODAL]);
  return (
    <div className="gx-px-5">
      <Form.Item
        label={"Role"}
        name="role"
        rules={[
          {
            required: true,
            message: "Please Enter !",
          },
        ]}
      >
        <Select onChange={onSelect}>
          {Role.map((Info) => {
            return (
              <Select.Option value={Info.value}>{Info.label}</Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      {fetchData === "instructor" && (
        <Form.Item
          label={"Instructor"}
          name="instructor"
          rules={[
            {
              required: true,
              message: "Please Enter !",
            },
          ]}
        >
          <Select onChange={(e) => setinstectorId(e)}>
            {instructorLists &&
              instructorLists.length > 0 &&
              instructorLists.map((Info) => {
                return (
                  <Select.Option value={Info.instructorId}>
                    {Info.fullName}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        name="meetingDate"
        label={intlPlaceholder("reservation.date")}
        rules={[
          {
            required: true,
            message: intlPlaceholder("reservationDate.message"),
          },
        ]}
      >
        <DatePicker
          disabled={fetchData === "" ? true : false}
          format={"MM-DD-YYYY"}
          onChange={(e) => onChange(e)}
          disabledDate={(current) =>
            current.isBefore(moment().subtract(1, "day"))
          }
          className="gx-w-100"
        />
      </Form.Item>

      {studentSuccess && studentSuccess ? (
        <Spin className="gx-d-flex gx-align-items-center gx-justify-content-center"></Spin>
      ) : (
        !studentSuccess &&
        TimeDetails && (
          <Form.Item
            name="meetingTime"
            label={intlPlaceholder("reservation.time")}
            rules={[
              {
                required: true,
                message: intlPlaceholder("reservationTime.message"),
              },
            ]}
          >
            <Select placeholder={intlPlaceholder("reservation.time")}>
              {TimeDetails &&
                TimeDetails.map((Item) => {
                  return <Select.Option value={Item}>{Item}</Select.Option>;
                })}
            </Select>
          </Form.Item>
        )
      )}

      <Form.Item
        label={intlPlaceholder("reservation.textarea")}
        name="meetingAgenda"
        rules={[
          {
            required: true,
            message: intlPlaceholder("reservationTextArea.message"),
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </div>
  );
};

export default ReservationForm;
