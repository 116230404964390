import React from "react";
import {Empty} from "antd";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const data = [
  { name: "Jan", spanish: 18, computer: 10, math: 15, english: 30 },
  { name: "Feb", spanish: 12, computer: 10, math: 15, english: 30 },
  { name: "Mar", spanish: 12, computer: 10, math: 15, english: 30 },
];

let dataColor = ["#4791FF", "#FF2366", "#02BC77", "#FFD950"];

const TinyBarChart = ({ quizAssignmentList, subjectName }) => {
  return(
    
      quizAssignmentList && quizAssignmentList.length > 0 ?(
        <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={quizAssignmentList}
          margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="4 4" />
          <Tooltip />
          <Legend />
          {subjectName &&
            subjectName.map((item, index) => {
              return <Bar dataKey={item} fill={dataColor[index]} />;
            })}
    
          {/* <Bar dataKey="spanish" fill="#4791FF" />
          <Bar dataKey="computer" fill="#FF2366" />
          <Bar dataKey="math" fill="#02BC77" />
          <Bar dataKey="english" fill="#FFD950" /> */}
        </BarChart>
      </ResponsiveContainer>
      ):(
        <div className="gx-d-flex gx-align-items-cener gx-justify-content-center">
        <Empty />
        </div>
      )
     
  )

 
};

export default TinyBarChart;
