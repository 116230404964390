import React, { memo, useEffect } from "react";
import MainApp from "./MainApp";
import LoginPage from "../../components/Auth/Login";
import AppLocale from "lngProvider";
import ForgetPage from "../../components/Auth/ForgetPassword";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { IntlProvider } from "react-intl";

import { setInitUrl } from "redux/actions/CommonHttp";
import SetNewPassword from "../../components/Auth/setUpNewPassword.js/newPassword";

const RestrictedRoute = ({
  component: Component,
  location,
  token,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const App = () => {
  const dispatch = useDispatch();
  const { token, initURL } = useSelector(({ auth }) => auth);
  const { locale } = useSelector(({ settings }) => settings);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const currentAppLocale = AppLocale[locale.locale];
  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/style.css";
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
  }, [dispatch, initURL, location.pathname, location.search]);

  useEffect(() => {
    if (location.pathname === "/") {
      if (token === null) {
        history.push("/login");
      } else if (initURL === "" || initURL === "/" || initURL === "/home") {
        history.push("/login");
      } else {
        history.push(initURL);
      }
    }
  }, [token, initURL, location, history]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/login/:query" component={LoginPage} />
        <Route exact path="/forgetPassword" component={ForgetPage} />
        <Route exact path="/setNewPassword/:query" component={SetNewPassword} />
      
        <RestrictedRoute
          path={`${match.url}`}
          token={token}
          location={location}
          component={MainApp}
        />
          {/* <Route  component={PageError} /> */}
      </Switch>
    </IntlProvider>
  );
};

export default memo(App);
