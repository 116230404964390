import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard/index";
import Support from "./Support";
import NoticeBoard from "./Noticeboard";
import Setting from "./Setting";
import Quiz from "./Quiz";
import SalesReport from "./Financial/salesReport";
import FinancialSummary from "./Financial/financialSummary";
import Meeting from "./Meeting";
import AllCourses from "./Courses/AllCourses";
import MyCourses from "./Courses/MyCourses";
import Notification from "./Notification";
import Assignment from "./Assignment";
import ViewSalesReport from "./Financial/salesReport/components/viewSalesReport";
import ViewSupportSubject from "./Support/components/viewSupportSubject";
import PageError from "./Pageerror";
const App = ({ match }) => (
  <Switch>
    <Route path={`${match.url}dashboard`} component={Dashboard} />
    <Route path={`${match.url}support`} component={Support} />
    <Route path={`${match.url}noticeboard`} component={NoticeBoard} />
    <Route path={`${match.url}assignment`} component={Assignment} />
    <Route path={`${match.url}financial/salesReport`} component={SalesReport} />
    <Route path={`${match.url}financial/financialSummary`} component={FinancialSummary} />
    <Route path={`${match.url}quiz`} component={Quiz} />
    <Route path={`${match.url}meeting`} component={Meeting} />
    <Route path={`${match.url}courses/mycourses`} component={MyCourses} />
    <Route path={`${match.url}courses/Allcourses`} component={AllCourses} />
    <Route path={`${match.url}viewSalesReport`} component={ViewSalesReport} />
    <Route
      path={`${match.url}viewSupportSubject`}
      component={ViewSupportSubject}
    />
    <Route path={`${match.url}notification`} component={Notification} />
    <Route path={`${match.url}setting`} component={Setting} />
    <Route path={`${match.url}*`} component={PageError} />
  </Switch>
);

export default App;
