import { Card,Empty } from "antd";
import React from "react";
import { MdOutlineNextPlan } from "react-icons/md";
import { BsArrowUpRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const DashBoardMettingCard = ({ upComingMeetings }) => {
  const history = useHistory();

 

  const renderMeeting = () => {
    let colors = ["#39D5CF", "#ED457C", "#39D5CF", "#39D5CF"];
    return (
      upComingMeetings &&
      upComingMeetings.map((item, index) => {
        item.color = colors[index];
        return (
          <div
            className="gx-d-flex   gx-align-items-center "
            style={{ gap: "13px" }}
          >
            <i
              className=" gx-d-flex gx-align-items-center gx-justify-content-center"
              style={{
                background: item.color,
                borderRadius: "50%",
                width: "33px",
                height: "33px",
              }}
            >
              <MdOutlineNextPlan size={23} fill={"#FFFFFF"} />
            </i>

            <div>
              <span className="gx-font-sans-bold gx-font-16">
                {item.fullName}
              </span>
              <p className="gx-font-sans-regular">{item.meetingTime}</p>
            </div>
          </div>
          
        );
      
    })
      );
  };

  return (
    <Card
    
      title={
        <span style={{ fontSize: "20px", textTransform: "capitalize", color:"#272D3B" }}>
          Upcoming
        </span>
      }
      className="gx-card gx-py-0"
      bodyStyle={{ padding: "10px", height: "180px"}}
      extra={
        <span className="gx-d-none gx-d-sm-block gx-pointer gx-mb-0 gx-font-sans-regular">
          Today
        </span>
      }
    >
      {
      upComingMeetings && upComingMeetings.length > 0 ? (
      renderMeeting()
      ):(
        <Empty />
      )
      }

      <div style={{ float: "right"}}>
        <i
          onClick={() => history.push("/meeting/request")}
          className="gx-d-flex gx-align-items-center gx-justify-content-center gx-pointer"
          style={{
            background: "#02CCF2",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            
          }}
        >
          <BsArrowUpRight  size={19} fill={"#FFFFFF"} />
        </i>
      </div>
    </Card>
  );
};

export default DashBoardMettingCard;
