import React, { useState } from "react";
import QuizList from "components/Table/PageNationTable";
import Layout from "components/Layout";
import ViewQuizzes from  "./components/viewQuizzes"
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import ShowModal from "components/Modal/ShowModal";
import { renderStatus } from "../../utils/commonUseFunction";
import { intlPlaceholder } from "util/intlPlaceholder";
import moment from "moment";
import ChartQuiz from "./components/ChartQuiz";

const Quiz = () => {
  const [filterValue, setFilterValue] = useState({});

  const columns = [
    {
      title: intlPlaceholder("quiz.ID"),
      render: (record) => (
        <span className="gx-text-capitalize">{
          record.quizId || "----"}</span>
      ),
      key: "quizId", 
    },
    {
      title: intlPlaceholder("quiz.title"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.quizTitle || "----"}</span>
      ),
      key: "title",
    },
    {
      title: intlPlaceholder("quiz.course"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.courseTitle || "----"}</span>
      ),
      key: "course",
    },
    {
      title: intlPlaceholder("quiz.questions"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.numberOfAttempts || "----"}</span>
      ),
      key: "questions",
    },
    {
      title: intlPlaceholder("quiz.time"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.quizTime || "----"}</span>
      ),
      key: "time",
    },
    {
      title: intlPlaceholder("quiz.totalMark"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.totalMarks || "----"}</span>
      ),
      key: "totalMark",
    },
    {
      title: intlPlaceholder("quiz.passMark"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.passMarks || "----"}</span>
      ),
      key: "passMark",
    },
    {
      title: intlPlaceholder("quiz.date"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.quizDate).format("DD-MMM-YYYY") || "----"}
        </span>
      ),
      key: "date",
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {"Attachments"}
        </span>
      ),
      render: (record) => (
        <span className="gx-text-capitalize gx-link gx-text-blue">
         {record.attachFileUrl.length>0 ?( <ShowModal
            modalName={"viewQuizzes_Modal"}
            buttonName="Attachment"
            record={record}
          />):"----"}
        </span>
      ),
      key: "attachFile",
    },
    {
      title: intlPlaceholder("quiz.status2"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status) || "----"}
        </span>
      ),
      key: "status2",
    },
  ];

  return (
    <Layout>
      <ChartQuiz />
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <QuizList
          apiName="getQuizzesForStudent"
          recordName="quizList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
      <ViewQuizzes/>
    </Layout>
  );
};

export default Quiz;
