import React from "react";
import DateRangPicker from "components/Filters/DateRangPicker";
import SearchBox from "../../../../components/Filters/SearchBox/index";
import { Typography } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
const { Title } = Typography;
const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3 "
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold gx-mb-0">
        {intlPlaceholder("sales.pageTitle")}
      </Title>

      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
        <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="getStudentPurchasedReceipt"
          requestType="FetchRecord"
          recordName="salesReportList"
          width="230px"
        />
        <SearchBox
          apiName="getStudentPurchasedReceipt"
          requestType="FetchRecord"
          placeholder={intlPlaceholder("summarysaledate.searchBox")}
          recordName="salesReportList"
          width="230px"
          customQuery={{ query: "search", key: "name" }}
          useSetFilterValue={true}
          setFilterValue={setFilterValue}
        />
      </div>
    </div>
  );
};

export default TableHeader;
