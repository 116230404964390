import { Col, Row } from "antd";
import React from "react";
import { FaThList } from "react-icons/fa";
import ChartCard from "components/ChartCard";
import PathYellow from "assets/images/Path.png";
import PathBlue from "assets/images/Path38118.png";
import PathGreen from "assets/images/Path38119.png";
import { useSelector } from "react-redux";

const ChartQuiz = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const { cardRecord } = curdProps;
 console.log("===cardRecord", cardRecord)
    return (
    <div>
      <Row className="gx-mb-2 gx-h-50">
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#EFD7FB"
            title={"quiz.Card1Title"}
            count={(cardRecord && cardRecord.length && cardRecord[0].totalQuiz) || 0}
            icon={<FaThList size={30} fill="#A771E9" />}
            graph={<img src={PathBlue} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#D4F4E8"
            title={"quiz.Card2Title"}
            count={(cardRecord && cardRecord.length && cardRecord[0].ActiveQuiz)|| 0}
            icon={<FaThList size={30} fill="#3DBC78" />}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#FADADC"
            title={"quiz.Card3Title"}
            count={(cardRecord && cardRecord.length &&  cardRecord[0].closeQuiz)|| 0}
            icon={<FaThList size={30} fill="#F6422E" />}
            graph={<img src={PathYellow} alt="graph" />}
          />
        </Col>
      </Row>
    </div> 
  );
};

export default ChartQuiz;
