import React from "react";
import SearchBox from "components/Filters/SearchBox";
import SelectFilter from "components/Filters/SelectFilter";
import DateRangPicker from "components/Filters/DateRangPicker";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Typography } from "antd";
const { Title } = Typography;
const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-justify-content-between gx-mb-3 "
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold ">
        {intlPlaceholder("quiz.pageTitle")}
      </Title>
      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
      <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="getQuizzesForStudent"
          recordName="quizList"
          requestType="FetchTableRecord"
          width="230px"
        />

        <SearchBox
          apiName="getQuizzesForStudent"
          recordName="quizList"
          requestType="FetchTableRecord"
          width={"220px"}
          placeholder={intlPlaceholder("quiz.searchBox")}
          customQuery={{ query: "search", key: "name" }}
          useSetFilterValue={true}
          setFilterValue={setFilterValue}
        />
        <SelectFilter
          apiName="getQuizzesForStudent"
          recordName="quizList"
          requestType="FetchTableRecord"
          placeholder={intlPlaceholder("quiz.select2")}
          searchKey="status"
          width={"200px"}
          isShowingAll={false}
          options={[
            { status: "active", label: "Active" },
            { status: "close", label: "Close" },
          ]}
          ItemValue="status"
          label="label"
        />
       
      </div>
    </div>
  );
};

export default TableHeader;
