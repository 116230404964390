import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import TableHeader from "./components/TableHeader";
import { DataGetAction } from "redux/actions/CommonHttp";

import { useDispatch, useSelector } from "react-redux";
import ChartCourse from "./components/ChartCourse";
import CourseCard from "./components/courseCard";
import { Pagination } from "antd";

const Index = () => {
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const { myCourse,cardRecord } = curdProps;
  let myCourseList=  myCourse && myCourse["tableData"];
  console.log("=====myCourseList",myCourseList);
  const totalNum = (myCourse && myCourse["total"]) || 0;
  console.log("====myCourses",myCourse,totalNum);
  const [filterValue, setFilterValue] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const [limit, setLimit] = useState(3);
  const getNextPageData = (page, pageSize) => {
    setPageNumber(page);
  };
  const getSizePageChangeData = (current, size) => {
    setLimit(size);
  };


  const fetchCourseData = () => {
    dispatch(
      DataGetAction(
        "getStudentEnrolledCourses",
        "FetchTableRecord", 
        { query: "all" },
        "StartSpinner",
        "",
        "myCourse"
      ) 
    );
  };
  useEffect(fetchCourseData, []);

  const fetchList = () => {
    let query = {
      query: "all",
      ...filterValue,
      pageNumber,
      limit,
    };
    dispatch(
      DataGetAction(
        "getStudentEnrolledCourses",
        "FetchTableRecord",
        query,
        "StartSpinner",
        "",
        "myCourse"
      )
    );
  };
  useEffect(fetchList, [pageNumber, limit]);



  // const setUserStatus = (_id, check) => {
  //   const status = check === "online" ? "offline" : "online";
  //   dispatch(
  //     DataRequestAction(
  //       "PUT",
  //       "updateCourseStatus",
  //       "EditRecord",
  //       { _id: _id, status: status },
  //       "StartSpinner",
  //       "",
  //       "myCourseList"
  //     )
  //   );
  // };

  // const renderActions = (record) => {};

  return (
    <Layout>
        <ChartCourse cardRecord={cardRecord} /> 
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <CourseCard myCourseList={myCourseList} />
        <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
        <Pagination
          pageSize={3} 
          total={totalNum}
          current={pageNumber}
          onChange={getNextPageData}
          onShowSizeChange={getSizePageChangeData}
        /></div>
      </Widget>
    </Layout>
  );
};

export default Index;
