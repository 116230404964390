import React from "react";
import { Typography } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import DateRangPicker from "components/Filters/DateRangPicker";
const { Title } = Typography;
const TableHeader = () => {
  return (
    <div
      className="gx-d-flex gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold gx-mb-2">
        {intlPlaceholder("noticeboard.pageTittle")}
      </Title>

      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
        <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="viewNoticesForStudent"
          recordName="noticeBoardList"
          width="230px"
        />
      
      </div>
    </div>
  );
};

export default TableHeader;
