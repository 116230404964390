import React from "react";
// import ShowModal from "components/Modal/ShowModal";
// import ActionDropdown from "components/Table/Actions";
import Image from "assets/images/student.jpeg";
import { AiFillHeart } from "react-icons/ai";
// import { intlPlaceholder } from "util/intlPlaceholder";
import { Row, Col, Card, Rate, Empty, Tag,message } from "antd";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
const CourseListIndex = () => {
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const { courseList,success,error } = curdProps;
  let data = courseList && courseList["tableData"];
  const setUserStatus = (_id) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "likeCourse",
        "FetchTableRecord",
        { _id: _id }, 
        "StarSpinner",
        "",
        "courseList"
      )
    );
  };
  if (success) {
    message.success(curdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }

  if (error) {
    message.error(curdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }
  // const renderActions = (record) => {
  //   record.startedDate = record && moment(record.startedDate);
  //   return (
  //     <div
  //       style={{ gap: "5px" }}
  //       className="gx-d-flex gx-flex-column popoverContent"
  //     >
  //       {record.courseStatus === "active" && (
  //         <ShowModal
  //           modalName={"EDIT_Course_MODAL"}
  //           buttonName="Edit"
  //           record={record}
  //         />
  //       )}
  //       {record.courseStatus === "block" && (
  //         <span
  //           style={{ cursor: "pointer" }}
  //           onClick={() => setUserStatus(record._id, "active", "courseStatus")}
  //         >
  //           Active
  //         </span>
  //       )}
  //       {record.courseStatus === "active" && (
  //         <span
  //           style={{ cursor: "pointer" }}
  //           onClick={() => setUserStatus(record._id, "block", "courseStatus")}
  //         >
  //           Block
  //         </span>
  //       )}

  //       {/* <DeleteConfirm
  //         type="deleteCourse"
  //         selectedItem={record}
  //         apiRequest="DeleteTableRecord"
  //         recordName="courseList"
  //         buttonName={intlPlaceholder("status.buttonName")}
  //         oKText={intlPlaceholder("status.oKText")}
  //         title={intlPlaceholder("status.deleteTitle")}
  //         content={intlPlaceholder("status.deleteContent")}
  //         Icon={intlPlaceholder("status.actionDeleteName")}
  //       /> */}

  //       <DeleteStatus
  //         id={record._id}
  //         Status="deleted"
  //         KeyObj={"courseStatus"}
  //         statusApiName="deleteCourse"
  //         recordName="courseList"
  //         apiRequest="DeleteTableRecord"
  //         buttonName={intlPlaceholder("status.buttonName")}
  //         oKText={intlPlaceholder("status.oKText")}
  //         title={intlPlaceholder("status.deleteTitle")}
  //         content={intlPlaceholder("status.deleteContent")}
  //         Icon={intlPlaceholder("status.actionDeleteName")}
  //       />
  //     </div>
  //   );
  // };

  return (
    <>
      {data && data.length > 0 ? (
        data.map((item) => {
          return (
            <Card
              bodyStyle={{
                padding: "0",
                boxShadow: "0px 0px 16px #5C5C5CCC",
                borderRadius: "8px",
              }}
              className="gx-course-card"
            >
              <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  xs={24}
                  sm={24}
                  className="gx-px-0"
                  style={{ position: "relative" }}
                >
                  <img
                    src={
                      (item.courseImageUrl &&
                        item.courseImageUrl.length &&
                        item.courseImageUrl[0].imageUrl) ||
                      Image
                    }
                    alt=""
                    width={"100%"}
                    height={"250px"}
                  />
                  <div
                    className="gx-text-center"
                    style={{
                      position: "absolute",
                      top: 12,
                      right: 12,
                      width: "78px",
                      height: "24px",
                      backgroundColor: "#041649",
                      border: "1px solid #D2D2D280",
                      borderRadius: "50px 0px",
                    }}
                  >
                    <span
                      className="gx-text-white "
                      style={{ fontSize: "12px" }}
                    >
                      {item.status}
                    </span>
                  </div>
                </Col>
                <Col
                  xl={19}
                  lg={19}
                  md={19}
                  xs={24}
                  sm={24}
                  className="gx-pr-2 gx-pl-3 gx-pt-2 gx-pb-2"
                >
                  <div className="gx-d-flex  gx-align-items-center gx-justify-content-between  gx-mb-1">
                    <span
                      className="gx-font-sans-medium gx-font-14 gx-text-capitalize"
                      style={{ color: "#5EC42D" }}
                    >
                      {item.courseTitle}
                    </span>
                    <div>
                      <AiFillHeart
                        style={{ fontSize: "25px" }}
                        fill={item.favoriteCourse ? "#F6422E" : "#D3D3D3"}
                        onClick={() =>
                          setUserStatus(item._id, item.status, "status")
                        }
                      />
                      {/* <span
                        className="gx-font-sans-regular gx-d-md-none gx-d-block gx-font-14 "
                        style={{ color: "#1A1A1A" }}
                      >
                        <ActionDropdown>{renderActions(item)}</ActionDropdown>
                      </span> */}
                    </div>
                  </div>

                  <div className="gx-mb-3 gx-d-flex gx-flex-row">
                    <span>
                      <Rate value={item.courseRating} disabled={true} />
                    </span>
                    <span className="gx-ml-3 gx-text-center gx-text-white gx-mt-1">
                      <Tag color={"#5EC42D"}> {item.courseRating}</Tag>
                    </span>
                  </div>
                  <div>
                    <Tag
                      className="gx-text-capitalize"
                      color={item.courseStatus === "active" ? "#5EC42D" : "red"}
                    >
                      {" "}
                      {item.courseStatus}
                    </Tag>
                  </div>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={12} xs={12}>
                      <Row className="gx-pb-2">
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            ID
                          </span>
                        </Col>

                        <Col xl={5} lg={5} md={5} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Course Title
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Course Code
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Duration
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Date
                          </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} xs={24} sm={23}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Max Students
                          </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14"></span>
                        </Col>
                      </Row>
                    </Col>

                    <Col xl={24} lg={24} md={24} sm={12} xs={12}>
                      <Row>
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.courseId}
                          </span>
                        </Col>

                        <Col xl={5} lg={5} md={5} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14 gx-text-capitalize"
                            style={{ color: "#1A1A1A" }}
                          >
                            {" "}
                            {item.courseTitle}
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          {" "}
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.courseCode}
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                          {" "}
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.courseDuration}hr
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {moment(item.startedDate).format("DD-MM-YYYY")}
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14 gx-text-center"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.maximumStudent}
                          </span>
                        </Col>
                        {/* <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-d-md-block gx-d-none gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            <ActionDropdown>
                              {renderActions(item)}
                            </ActionDropdown>
                          </span>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          );
        })
      ) : (
        <Empty />
      )}
    </>
  );
};

export default CourseListIndex;
