import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import TableHeader from "./components/TableHeader";
import { DataGetAction } from "redux/actions/CommonHttp";

import { useDispatch, useSelector } from "react-redux";
import ChartCourse from "./components/ChartCourse";
import CourseCard from "./components/courseCard";
import { Pagination } from "antd";

const Index = () => {
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const { courseList, cardRecord } = curdProps;
  const totalNum = (courseList && courseList["total"]) || 0;
  console.log("====courseLists",courseList,totalNum);
  const [filterValue, setFilterValue] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const [limit, setLimit] = useState(3);
  const getNextPageData = (page, pageSize) => {
    setPageNumber(page);
  };
  const getSizePageChangeData = (current, size) => {
    setLimit(size);
  };

  const fetchCourseData = () => {
    dispatch(
      DataGetAction(
        "getCourse",
        "FetchTableRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "courseList"
      )
    );
   
  };
  useEffect(fetchCourseData, []);
  
  const fetchList = () => {
    let query = {
      query: "all",
      ...filterValue,
      pageNumber,
      limit,
    };
    dispatch(
      DataGetAction(
        "getCourse",
        "FetchTableRecord",
        query,
        "StartSpinner",
        "",
        "courseList"
      )
    );
  };
  useEffect(fetchList, [pageNumber, limit]);

  return (
    <Layout>
      <ChartCourse cardRecord={cardRecord} /> 
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <CourseCard />
        <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
        <Pagination
          pageSize={3} 
          total={totalNum}
          current={pageNumber}
          onChange={getNextPageData}
          onShowSizeChange={getSizePageChangeData}
        /></div>
      </Widget>
    </Layout>
  );
};

export default Index;

