import React, { useEffect, useState } from "react";
import Layout from "components/Layout";
import Widget from "../../components/Widget";
import moment from "moment";
import ShowModal from "components/Modal/ShowModal";
import NotificationList from "./components/viewForm";
import { Col, Row, Button, Spin, Empty,Card, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/CommonHttp";

const Notification = () => {
  const dispatch = useDispatch();
  const notificationProps = useSelector((state) => state.CurdR);
  const { notificationList, Loader } = notificationProps;
  console.log("===notificationList===", notificationList);
  let totalNum = notificationList && notificationList["total"] ;
  let notificationData = notificationList && notificationList["tableData"];
  console.log("====notificationList", notificationData);
  const [pageNumber, setPageNumber] = useState(1);

  const [limit, setLimit] = useState(10);
  const getNextPageData = (page, pageSize) => {
    setPageNumber(page);
  };
  const getSizePageChangeData = (current, size) => {
    setLimit(size);
  };

  const fetchNotification = () => {
    dispatch(
      DataGetAction(
        "getNotificationForAdmin",
        "FetchRecord",
        { query: "all", pageNumber, limit },
        "StartSpinner",
        "",
        "notificationList"
      )
    );
  };

  useEffect(fetchNotification, pageNumber, limit, []);

  return (
    <Layout>
      <Widget>
        {Loader && <Spin></Spin>}

        {!Loader && (
          <>
            {notificationData && notificationData.length > 0 ? (
              notificationData.map((item) => {
                return (
                  
                  <Card bodyStyle={{borderRadius: "8px"}}className="gx-notification-card ">
                    <Row className="gx-align-items-center">
                      <Col xl={2} lg={2} mb={2} sm={12} xs={6}>
                      <p className="iconColor" />
                      </Col>
                      <Col xl={6} lg={6} mb={6} sm={12} xs={12}>
                        <div className="gx-d-flex gx-flex-column   gx-justify-content-center ">
                          <span className="gx-font-sans-bold gx-font-18 ">
                            {item.title
                              ? item.title.length > 25
                                ? item.title.slice(0, 20) + "...."
                                : item.title
                              : ""}
                          </span>

                          <span className="gx-font-sans-regular gx-font-14">
                            {moment(item.createdAt).format("MM-DD-YYYY")}
                          </span>
                        </div>
                      </Col>
                      <Col xl={12} lg={12} mb={12} sm={12} xs={24}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.description
                              ? item.description.length > 100
                                ? item.description.slice(0, 90) + "...."
                                : item.description
                              : "",
                          }}
                          className="gx-mb-0 gx-font-sans-regular gx-font-16"
                        />
                      </Col>
                      <Col xl={4} lg={4} mb={4} sm={12} xs={24}>
                        <div>
                          <ShowModal
                            modalName={"View_Notification_Modal"}
                            record={item}
                          >
                            <Button style={{color:"#FFFFFF"}} className="gx-w-100 gx-mb-0 gx-gradient-button">
                              View
                            </Button>
                          </ShowModal>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                );
              })
            ) : (
              <Card>
                <Empty />
              </Card>
            )}
          </>
        )}
         <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
          <Pagination
            pageSize={10}
            total={totalNum}
            current={pageNumber}
            onChange={getNextPageData}
            onShowSizeChange={getSizePageChangeData}
          />
        </div>
      </Widget>
      <NotificationList />
    </Layout>
  );
};
export default Notification;
