import React from "react";
import { intlPlaceholder } from "util/intlPlaceholder";
import FormModal from "components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form, Input, Rate } from "antd";

const RateMyCourse = ({ myCourseList }) => {
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_MyCourse_MODAL, initialValues } = curdProps;

  return (
    <div>
      <FormModal
        modalTitle={
          <p className="gx-d-flex">
            Rate
            <p
              dangerouslySetInnerHTML={{
                __html: `<span style='font-weight:bold;padding:0 4px;'>${
                  initialValues && initialValues.courseTitle
                }<span/>`,
              }}
            />
            Course
          </p>
        }
        editApiName="enrolledCourseRating"
        recordName={"myCourse"}
        ModalName="EDIT_MyCourse_MODAL"
        visible={EDIT_MyCourse_MODAL}
        form={Form}
        initialValues={initialValues}
        width={650}
        isShowButton={false}
        extraFieldName={"courseId"}
        extraFieldValue={initialValues && initialValues._id}
      >
        <div className="gx-px-3 gx-py-2">
          <Form.Item label={intlPlaceholder("Rate Course")} name="courseRating">
            <Rate />
          </Form.Item>
          <Form.Item label={intlPlaceholder("Description")} name="courseReview">
            <Input.TextArea
              placeholder={intlPlaceholder(
                "Write Detailed Review Here (Optional)"
              )}
              rows={6}
            />
          </Form.Item>
        </div>
      </FormModal>
    </div>
  );
};

export default RateMyCourse;
