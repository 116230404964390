import React, { useState, useEffect } from "react";
import RequestList from "components/Table/PageNationTable";
import ActionDropdown from "components/Table/Actions";
import ReservationChart from "./components/ReservationCharts";
import Widget from "components/Widget";
import TableHeader from "./components/TableHeader";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch } from "react-redux";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import { renderStatus } from "../../utils/commonUseFunction";
import moment from "moment";
import Layout from "components/Layout";
import { intlPlaceholder } from "util/intlPlaceholder";

const Reservation = () => {
  const [filterValue, setFilterValue] = useState({});
  const dispatch = useDispatch();

  const fetchInstructor = () => {
    dispatch(
      DataGetAction(
        "getMyInstructorList",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "instructorLists"
      )
    );
  };
  useEffect(fetchInstructor, []);

  const renderActions = (record) => {
    return (
      <div
        style={{ gap: "5px" }}
        className="gx-d-flex gx-flex-column popoverContent"
      >
        <DeleteConfirm
          type="deleteMeetingRequest"
          recordName="reservationList"
          selectedItem={record}
          apiRequest="DeleteTableRecord"
          buttonName={intlPlaceholder("meeting.buttonName")}
          oKText={intlPlaceholder("meeting.oKText")}
          title={intlPlaceholder("meeting.deleteTitle")}
          content={intlPlaceholder("meeting.deleteContent")}
          Icon={intlPlaceholder("meeting.actionDeleteName")}
        />
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("request.ID"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.meetingId || "----"}</span>
      ),
      key: "requestId",
    },
    {
      title: intlPlaceholder("request.day"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.reservationDay || "----"}
        </span>
      ),
      key: "day",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{"Reservation With"}</span>,
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.reservationWithName || "----"}
        </span>
      ),
      key: "ReservationWith",
    },
    {
      title: intlPlaceholder("request.date"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.meetingDate).format("MM-DD-YYYY") || "----"}
        </span>
      ),
      key: "date",
    },
    {
      title: intlPlaceholder("request.time"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.meetingTime || "----"}
        </span>
      ),
      key: "time",
    },
    {
      title: intlPlaceholder("instructor.Status"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status)}
        </span>
      ),
      key: "status",
    },
    {
      title: intlPlaceholder("request.meetingAgenda"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.meetingAgenda || "----"}
        </span>
      ),
      key: "meetingAgenda",
    },
    {
      title: "",
      render: (record) => (
        <ActionDropdown>{renderActions(record)}</ActionDropdown>
      ),
      key: "action",
      width: "50px",
    },
  ];
  //  const data = [
  //   {
  //     Id: "001",
  //     day:"Wed",
  //     date:"01Sep2022",
  //     time:"08:00AM-09:00Am",
  //     status:"pending",
  //     meetingAgenda:"Attendence issue",

  //   },
  // ];

  return (
    <Layout>
      <ReservationChart />
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <RequestList
          apiName="getReservationList"
          recordName="reservationList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
      {/* <Titleform /> */}
    </Layout>
  );
};
export default Reservation;
