import { Card, Col, Empty, Rate, Row,Tag } from "antd";
import Image from "assets/images/student.jpeg";
import moment from "moment";
import React from "react";

const DashboardCourses = ({myEnrolledCourses}) => {
    // const courseList = [
    //     {
    //       _id:"1",
    //       status: "Online",
    //       courseTitle: "English",
    //       courseRating: "5",
    //       courseStatus: "Delete",
    //       courseId: "01",
    //       courseCode: "1002",
    //       courseDuration: "20",
    //       courseStartedDate: "12-09-21",
    //       coursemaximumStudent: "12",
    //     },
    //     {
    //       _id:"2",
    //       status: "Online",
    //       courseTitle: "English",
    //       courseRating: "5",
    //       courseStatus: "Delete",
    //       courseId: "01",
    //       courseCode: "1002",
    //       courseDuration: "20",
    //       courseStartedDate: "12-09-21",
    //       coursemaximumStudent: "12",
    //     }
    //   ];
  return (
    <>
      {myEnrolledCourses && myEnrolledCourses.length > 0 ? (
        myEnrolledCourses.map((item) => {
          

          return (
            <Card
              bodyStyle={{
                padding: "0",
                boxShadow: "0px 0px 16px #5C5C5CCC",
                borderRadius: "8px",
                height:"60%"
              }}
              className="gx-card"
            >
              <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  xs={24}
                  sm={24}
                  className="gx-px-0"
                  style={{ position: "relative" }} 
                >
                  <img
                    src={item.courseImageUrl[0].imageUrl ? item.courseImageUrl[0].imageUrl : Image}
                    alt=""
                    width={"100%"}
                    height={"220px"}
                  />
                  <div
                    className="gx-text-center "
                    style={{
                      position: "absolute",
                      top: 12,
                      right: 12,
                      width: "78px",
                      height: "24px",
                      backgroundColor: "#041649",
                      border: "1px solid #D2D2D280",
                      borderRadius: "50px 0px",
                    }}
                  >
                    <span
                      className="gx-text-white gx-mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      {item.status}
                    </span>
                  </div>
                </Col>
                <Col
                  xl={19}
                  lg={19}
                  md={19}
                  xs={24}
                  sm={24}
                  className="gx-pr-2 gx-pl-3 gx-pt-2 gx-pb-2"
                >
                  <div className="gx-d-flex  gx-align-items-center gx-justify-content-between  gx-mb-1">
                    <span
                      className="gx-font-sans-medium gx-font-14 gx-text-capitalize"
                      style={{ color: "#5EC42D" }}
                    >
                      {item.courseTitle}
                    </span>
                  </div>

                  <div className="gx-mb-3 gx-d-flex gx-flex-row">
                    <span>
                      <Rate value={item.courseRating} disabled={true} />
                    </span>
                    <span className="gx-ml-3 gx-text-center gx-text-white gx-mt-1">
                      <Tag color={"#5EC42D"}> {item.courseRating}</Tag>
                    </span>
                  </div>
                  <div>
                    <Tag
                      className="gx-text-capitalize"
                      color={item.courseStatus === "active" ? "#5EC42D" : "red"}
                    >
                      {" "}
                      {item.courseStatus}
                    </Tag>
                  </div>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={12} xs={12}>
                      <Row className="gx-pb-2">
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            ID
                          </span>
                        </Col>

                        <Col xl={5} lg={5} md={5} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Course Title
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Course Code
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Duration
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Date
                          </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} xs={24} sm={23}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Max Students
                          </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14"></span>
                        </Col>
                      </Row>
                    </Col>

                    <Col xl={24} lg={24} md={24} sm={12} xs={12}>
                      <Row>
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.courseId}
                          </span>
                        </Col>

                        <Col xl={5} lg={5} md={5} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14 gx-text-capitalize"
                            style={{ color: "#1A1A1A" }}
                          >
                            {" "}
                            {item.courseTitle}
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          {" "}
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.courseCode}
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                          {" "}
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.courseDuration}hr
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {moment(item.startedDate).format("DD-MM-YYYY")}
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14 gx-text-center"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.maximumStudent}
                          </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-d-md-block gx-d-none gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          ></span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          );
        })
      ) : (
        <Empty />
      )}
    </>
  );
};

export default DashboardCourses;
