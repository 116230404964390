import { Col, Row, Card, Radio } from "antd";
import React, { useEffect } from "react";
import ChartCard from "../ChartCard";
import PathYellow from "assets/images/Path.png";
import PathRed from "../../assets/images/Path38055.png";

import PathBlue from "assets/images/Path38118.png";
import PathGreen from "assets/images/Path38119.png";
import { ImBooks } from "react-icons/im";
import { MdAssignment } from "react-icons/md";
import { FaThList } from "react-icons/fa";
import { FaMeetup } from "react-icons/fa";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import SendMoney from "./components/DashboardNotice";
import WelcomeCard from "./components/WelcomeCard";
import DashBoardMettingCard from "./components/DashBoardMettingCard";
import DashboardCourses from "./components/DashboardCourses";
import TinyBarChart from "./components/TinyBarChart";

const Index = () => {
  const dispatch = useDispatch();

  const adminProps = useSelector((state) => state.CurdR);
  const { dashboardData, quizAssignmentList } = adminProps;

  const onChange = (e) => {
    console.log("====e", e);
    let values = e ? e.target.value : "getQuizHistory";
    dispatch(
      DataGetAction(
        "getAssignmentAndQuizHistory",
        "FetchRecord",
        { query: values },
        "",
        "",
        "quizAssignmentList"
      )
    );
  };

  const fetchAdminDashboard = () => {
    dispatch(
      DataGetAction(
        "getStudentDashboardData",
        "FetchRecord",
        "",
        "gettingAdminDashboard",
        "",
        "dashboardData"
      )
    );
    onChange();
  };

  useEffect(fetchAdminDashboard, []);

  const cardTitls = (title) => {
    return (
      <span
        style={{ fontSize: "20px" }}
        className="h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium"
      >
        {title}
      </span>
    );
  };
  const history = useHistory();

  return (
    <div>
      <Row>
        <Col xl={17} lg={17} md={17} sm={17} xs={24}>
          <WelcomeCard
            waitingTickets={dashboardData && dashboardData.waitingTickets}
            pendingMeetingRequests={
              dashboardData && dashboardData.pendingMeetingCount
            }
            todayNoticeBoardCount={
              dashboardData && dashboardData.todayNoticeBoardCount
            }
          />
        </Col>
        <Col xl={7} lg={7} md={7} sm={24} xs={24}>
          <DashBoardMettingCard
            upComingMeetings={dashboardData && dashboardData.scheduleMeetings}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#F9DAE5"
            title={"dashboard.card1Title"}
            icon={<ImBooks size={29} fill="#FF2366" />}
            count={(dashboardData && dashboardData.enrolledCoursesCount) || 0}
            graph={<img src={PathRed} alt="graph" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#D4F4E8"
            title={"dashboard.card2Title"}
            icon={<MdAssignment size={29} fill="#3DBC78" />}
            count={(dashboardData && dashboardData.openAssignmentsCount) || 0}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#E0ECFE"
            title={"dashboard.card3Title"}
            icon={<FaThList size={29} fill="#4791FF" />}
            count={(dashboardData && dashboardData.openQuizzesCount) || 0}
            graph={<img src={PathBlue} alt="graph" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24} >
          <ChartCard
            bgColor="#FFF9E1"
            title={"dashboard.card4Title"}
            icon={<FaMeetup size={29} fill="#FFD950" />}
            count={(dashboardData && dashboardData.scheduleMeetingsCount) || 0}
            graph={<img src={PathYellow} alt="graph" />}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <SendMoney
            data={dashboardData && dashboardData.noticeBoardInfo}
            title="NoticeBoard"
            route={"noticeboard"}
          />
        </Col>

        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card className="gx-card" bodyStyle={{ height: "380px" }}>
            <Radio.Group
              onChange={onChange}
              defaultValue="getQuizHistory"
              className="gx-d-flex gx-ml-4 gx-mb-2 gx-justify-content-center gx-align-text-center"
              style={{ textAlign: "center" }}
            >
              <Radio.Button value="getQuizHistory" style={{ width: "200px" }}>
                Quiz History
              </Radio.Button>
              <Radio.Button value="getAssignmentHistory" style={{ width: "200px" }}>
                Assignments History
              </Radio.Button>
            </Radio.Group>
            <TinyBarChart
              quizAssignmentList={quizAssignmentList && quizAssignmentList.data}
              subjectName={quizAssignmentList && quizAssignmentList.subjectName}
            />
          </Card>
        </Col>
      </Row>

      <Card
        title={cardTitls("My Courses")}
        className="gx-card h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium"
        extra={
          <span
            onClick={() => history.push("/courses/mycourses")}
            className="gx-d-none gx-d-sm-block gx-pointer gx-mb-0"
            style={{ color: "#A212E8", fontSize: "17px" }}
          >
            View All
          </span>
        }
      >
        <DashboardCourses
          myEnrolledCourses={dashboardData && dashboardData.myEnrolledCourses}
        />
      </Card>
    </div>
  );
};

export default Index;
