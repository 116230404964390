import React, { useState } from "react";
import FinancialSummaryList from "components/Table/PageNationTable";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import moment from "moment";
import { renderStatus } from "../../../utils/commonUseFunction";
import { renderSubstring } from "../../../utils/commonUseFunction";
import { intlPlaceholder } from "util/intlPlaceholder";
const FinancialSummary = () => {
  const [filterValue, setFilterValue] = useState({});

  const columns = [
    {
      title: intlPlaceholder("FinancialSummary.transcetionId"),
      render: (record) => (
        <span className="gx-text-capitalize ">
          {record.transactionId || "----"}
        </span>
      ),
      key: "transcetionId",
      width: "500px",
    },

    {
      title: intlPlaceholder("FinancialSummary.description"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderSubstring(record.paymentDetails || "----", "50", "50") ||
            "----"}
        </span>
      ),
      key: "description",
      width: "700px",
    },

    {
      title: intlPlaceholder("FinancialSummary.initialBalance"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.initialBalance || 0}</span>
      ),
      key: "initialBalance",
      width: "670px",
    },
    {
      title: intlPlaceholder("FinancialSummary.amount"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.depositAmount || "----"}
        </span>
      ),
      key: "amount",
      width: "700px",
    },
    {
      title: intlPlaceholder("FinancialSummary.finalBalance"),
      render: (record) => (
        <span className="gx-text-capitalize ">{record.finalBalance}</span>
      ),
      key: "finalBalance",
      width: "650px",
    },
    {
      title: intlPlaceholder("FinancialSummary.date"),
      render: (record) => (
        <span className="gx-text-capitalize ">
          {moment(record.createdAt).format("MM-DD-YYYY") || "----"}
        </span>
      ),
      key: "date",
      width: "750px",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>Transaction Type</span>,
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.transactionType)}
        </span>
      ),
      key: "status",
      width: "100px",
    },
    {
      title: intlPlaceholder("FinancialSummary.creator"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.createdBy || "----"}</span>
      ),
      key: "creator",
      width: "300px",
    },
  ];

  return (
    <Layout>
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <FinancialSummaryList
          apiName="getStudentDepositAndRefund"
          recordName="financialSummaryList"
          requestType="FetchRecord"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
    </Layout>
  );
};

export default FinancialSummary;
