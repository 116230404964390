import React from "react";
import Image from "assets/images/student.jpeg";
import { Row, Col, Card, Empty, Rate, message } from "antd";
import moment from "moment-timezone";
import ShowModal from "components/Modal/ShowModal";
import RateMyCourse from "./RateMyCourse";
import { useDispatch, useSelector } from "react-redux";
const CourseList = ({ myCourseList }) => {
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const {  success, error } = curdProps;
  if (success) {
    message.success(curdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }

  if (error) {
    message.error(curdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }

  return (
    <>
      {myCourseList && myCourseList.length > 0 ? (
        myCourseList.map((item, index) => {
          return (
            <Card
              bodyStyle={{
                padding: "0",
                boxShadow: "0px 0px 16px #5C5C5CCC",
                borderRadius: "8px",
                height: "220px",
              }}
            >
              <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  xs={24}
                  sm={24}
                  className="gx-px-0"
                  style={{ position: "relative" }}
                >
                  <img
                    src={
                      (item.courseImageUrl &&
                        item.courseImageUrl.length &&
                        item.courseImageUrl[0].imageUrl) ||
                      Image
                    }
                    alt=""
                    width={"100%"}
                    height={"220px"}
                  />
                  <div
                    className="gx-text-center"
                    style={{
                      position: "absolute",
                      top: 12,
                      right: 12,
                      width: "78px",
                      height: "24px",
                      backgroundColor: "#041649",
                      border: "1px solid #D2D2D280",
                      borderRadius: "50px 0px",
                    }}
                  >
                    <span
                      className="gx-text-white "
                      style={{ fontSize: "12px" }}
                    >
                      {item.status}
                    </span>
                  </div>
                </Col>
                <Col
                  xl={19}
                  lg={19}
                  md={19}
                  xs={24}
                  sm={24}
                  className="gx-pr-2 gx-pl-3 gx-pt-2 gx-pb-2"
                >
                  <div className="gx-d-flex  gx-align-items-center gx-justify-content-between  gx-mb-1">
                    <span
                      className="gx-font-sans-medium gx-font-14 gx-text-capitalize"
                      style={{ color: "#5EC42D" }}
                    >
                      {item.courseTitle}
                    </span>
                  </div>
                  {item.courseRating ? (
                    <div className="gx-mb-3 gx-d-flex gx-flex-row">
                      <span>
                        <Rate value={item.courseRating} disabled={true} />
                      </span>
                    </div>
                  ) : (
                    <div className="gx-mb-3 gx-d-flex gx-flex-row">
                      <span>
                        {
                          <ShowModal
                            record={item}
                            className="gx-link gx-text-blue"
                            buttonName="myCourse.ReviewCourse"
                            modalName={"EDIT_MyCourse_MODAL"}
                          ></ShowModal>
                        }
                      </span>
                    </div>
                  )}

                  <Row>
                    <Col xl={24} lg={24} md={24} sm={12} xs={12}>
                      <Row className="gx-pb-2">
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            ID
                          </span>
                        </Col>

                        <Col xl={5} lg={5} md={5} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Course Title
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Course Code
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Duration
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Date
                          </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} xs={24} sm={23}>
                          <span className="gx-font-sans-regular gx-font-14">
                            Max Students
                          </span>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span className="gx-font-sans-regular gx-font-14"></span>
                        </Col>
                      </Row>
                    </Col>

                    <Col xl={24} lg={24} md={24} sm={12} xs={12}>
                      <Row>
                        <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.courseId}
                          </span>
                        </Col>

                        <Col xl={5} lg={5} md={5} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14 gx-text-capitalize"
                            style={{ color: "#1A1A1A" }}
                          >
                            {" "}
                            {item.courseTitle}
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          {" "}
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.courseCode}
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                          {" "}
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.courseDuration}hr
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14"
                            style={{ color: "#1A1A1A" }}
                          >
                            {moment(item.startedDate).format("DD-MM-YYYY")}
                          </span>
                        </Col>
                        <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                          <span
                            className="gx-font-sans-regular gx-font-14 gx-text-center"
                            style={{ color: "#1A1A1A" }}
                          >
                            {item.maximumStudent}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          );
        })
      ) : (
        <Empty />
      )}
      <RateMyCourse />
    </>
  );
};

export default CourseList;
