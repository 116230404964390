import React from "react";
import { Popover } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
const Index = ({ children }) => {
  return (
    <Popover placement="leftBottom" content={children} title="" trigger="hover">
      <span
        className="gx-pointer gx-d-flex"
        style={{ fontSize: "35px", fontWeight: "bold" }}
      >
        <EllipsisOutlined  />

      </span>
    </Popover>
  );
};

export default Index;
