import React from "react";
import SearchBox from "components/Filters/SearchBox";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Typography } from "antd";
const { Title } = Typography;
const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-justify-content-between gx-mb-3 "
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold ">
        {intlPlaceholder("course.pageTittle")}
      </Title>
      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
       

        <SearchBox
          apiName="getCourse"
          recordName="courseList"
          requestType="FetchTableRecord"
          width={"240px"}
          placeholder={intlPlaceholder("course.searchBox")}
          customQuery={{ query: "search", key: "name" }}
          useSetFilterValue={true}
          setFilterValue={setFilterValue}
        />

        
      </div>
    </div>
  );
};

export default TableHeader;
