import React from "react";
import { ImBooks } from "react-icons/im";
import { GoBell } from "react-icons/go";
import { MdDashboard, MdAssignment } from "react-icons/md";
import { AiFillDatabase } from "react-icons/ai";
import { FaMoneyBill, FaMeetup, FaHandsHelping } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
const List = {
  student: [
    {
      title: "sidebarItem.dashboard",
      url: "/dashboard",
      icon: <MdDashboard size={18} />,
      key: "dashboard",
      top: "3px",
    },

    {
      title: "sidebarItem.Course",
      url: "",
      icon: <ImBooks size={18} />,
      key: "courses",
      top: "3px",
      subMenu: [
        {
          title: "sidebarItem.allCourses",
          url: "/courses/allcourses",
          key: "/courses/allcourses",
          top: "3px",
        },
        {
          title: "sidebarItem.myCourses",
          url: "/courses/mycourses",
          key: "/courses/mycourses",
          top: "3px",
        },
      ],
    },

    {
      title: "sidebarItem.Assignment",
      url: "/assignment",
      icon: <MdAssignment size={18} />,
      key: "assignment",
      top: "3px",
    },

    {
      title: "sidebarItem.Quiz",
      url: "/quiz",
      icon: <AiFillDatabase size={18} />,
      key: "quiz",
      top: "3px",
    },
    {
      title: "sidebarItem.meeting",
      url: "/meeting",
      icon: <FaMeetup size={18} />,
      key: "meeting",
      top: "3px",
    },

    {
      title: "sidebarItem.Financial",
      url: "",
      icon: <FaMoneyBill size={18} />,
      key: "financial",
      top: "3px",
      subMenu: [
        {
          title: "sidebarItem.salesReport",
          url: "/financial/salesReport",

          key: "financial/salesReport",
          top: "3px",
        },
        {
          title: "sidebarItem.financialSummary",
          url: "/financial/financialSummary",

          key: "financial/financialSummary",
          top: "3px",
        },
      ],
    },

    {
      title: "sidebarItem.Support",
      url: "/support",
      icon: <FaHandsHelping size={18} />,
      key: "support",
      top: "3px",
    },
    {
      title: "sidebarItem.Noticeboard",
      url: "/noticeboard",
      icon: <BsFillFileEarmarkTextFill size={18} />,
      key: "noticeboard",
      top: "3px",
    },

    {
      title: "sidebarItem.Notification",
      url: "/notification",
      icon: <GoBell size={18} />,
      key: "notification",
      top: "3px",
    },

    {
      title: "sidebarItem.Setting",
      url: "/setting",
      icon: <IoSettingsSharp size={18} />,
      key: "setting",
      top: "3px",
    },
  ],
};

export default List;
