import React from "react";
import { Tag } from "antd";
import moment from "moment";

export const renderMoreHtmlDec = (record, modelName, dispatch) => {
  var html = record;
  var div = document.createElement("div");
  div.innerHTML = html;
  var text = div.textContent || div.innerText || "";

  if (text.length > 65) {
    return (
      <span
        className="gx-text-primary gx-link"
        onClick={() => {
          dispatch({
            type: "Show_Modal",
            payload: record,
            ModalName: modelName,
          });
        }}
      >
        Read more
      </span>
    );
  } else {
    return "";
  }
};
export const renderStatus = (status) => {
  if (status === "deposit") {
    return <Tag color="green">Deposit</Tag>;
  }
  if (status === "refund") {
    return <Tag color="red">Refund</Tag>;
  }
  if (status === "open") {
    return <Tag color="green">Open</Tag>;
  }
  if (status === "close") {
    return <Tag color="red">Close</Tag>;
  }
  if (status === "waiting") {
    return <Tag color="red">waiting</Tag>;
  }
  if (status === "resolved") {
    return <Tag color="green">resolved</Tag>;
  }
  if (status === "Active") {
    return <Tag color="#8656C0">Active</Tag>;
  }
  if (status === "schedule") {
    return <Tag color="blue">Schedule</Tag>;
  }
  if (status === "reject") {
    return <Tag color="red">Rejected</Tag>;
  }
  if (status === "cancelled") {
    return <Tag color="red">Cancelled</Tag>;
  }
  if (status === "completed") {
    return <Tag color="green">Completed</Tag>;
  }
  if (status === "delete") {
    return <Tag color="red">Deleted</Tag>;
  }
  if (status === "active") {
    return <Tag color="green">Active</Tag>;
  }
  if (status === "block") {
    return <Tag color="red">Block</Tag>;
  }
  if (status === "invite") {
    return <Tag color="blue">Invited</Tag>;
  }
  if (status === "invited") {
    return <Tag color="blue">Invited</Tag>;
  }
  if (status === "reinvited") {
    return <Tag color="blue">ReInvited</Tag>;
  }
  if (status === "pending") {
    return <Tag color="blue">pending</Tag>;
  }
  if (status === "deleted") {
    return <Tag color="red">Deleted</Tag>;
  }
  if (status === "confirm") {
    return <Tag color="#387F51">Confirm</Tag>;
  }
  if (status === "failed") {
    return <Tag color="#E68537">Failed</Tag>;
  }
  if (status === "publish") {
    return <Tag color="green">Publish</Tag>;
  } else {
    return <Tag color="green">Publish</Tag>;
  }
};
export const renderTiming = (timing) => {
  return (
    timing &&
    timing.length > 0 &&
    timing.map((item) => {
      return (
        <div>
          <span>{item.duration && item.duration}</span> -{" "}
          <span>{item.type && item.type}</span>
          <p>
            {item.exercise && item.exercise.length > 20
              ? item.exercise.substring(0, 30) + "..."
              : item.exercise}
          </p>
        </div>
      );
    })
  );
};
export const renderSubstring = (data, length, subtract) => {
  return (
    <span>{data && data.length > length ? data.substring(0, subtract) + "..." : data}</span>
  );
};
export const renderArticle = (record, length = 100, alpha = 65) => {
  var html = record;
  var div = document.createElement("div");
  div.innerHTML = html;
  var text = div.textContent || div.innerText || "";
  let substring =
    text && text.length > length ? text.slice(0, alpha) + "..." : text;
  return substring;
  // return element.slice(0,65)
};
export const renderDate = (date, format = "MMMM Do YYYY") => {
  return moment(date).format(format);
};
export const renderSubCategoryName = (data) => {
  let categoryId = data.category;

  let categoryName = "";
  if (categoryId && categoryId.length > 1) {
    console.log("===if======", categoryId);
    categoryId.map((Item, index) => {
      if (index > 0) {
        // if (categoryName === "") {
        //   if (children[Item].name) {
        //     console.log("=====empty====", categoryName, Item, children[Item]);
        //     categoryName = children[Item] && children[Item].name + "/ ";
        //   }
        //   if (children[Item].children) {
        //     children = children[Item] && children[Item].children;
        //     console.log("====== children =====", children);
        //   }
        // }
        //  else if (categoryId.length - 1 === index) {
        //   if (children[Item].name) {
        //     console.log("====eq=====", categoryName, Item, children[Item]);
        //     categoryName =
        //       categoryName + (children[Item] && children[Item].name);
        //   }
        // }
        // else {
        //   if (children[Item].name) {
        //     console.log("====else=====", categoryName, Item, children[Item]);
        //     categoryName =
        //       categoryName + children[Item] && children[Item].name + "/ ";
        //   }
        //   if (children[Item].children) {
        //     children = children[Item] && children[Item].children;
        //     console.log("====== children =====", children);
        //   }
        // }
      } else {
        categoryName = "";
      }
      return Item;
    });
  }

  if (categoryName === "") {
    categoryName = "---";
  }

  return categoryName;
};
export const showModal = (dispatch, record, modelName) => {
  dispatch({
    type: "Show_Modal",
    payload: record,
    ModalName: modelName,
  });
};
