import React, { useState } from "react";
import AssignmentList from "components/Table/PageNationTable";
import { renderStatus } from "utils/commonUseFunction";
import Layout from "components/Layout";
import ShowModal from "components/Modal/ShowModal";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import ViewAssignments from "./components/viewAssignments";
import { intlPlaceholder } from "util/intlPlaceholder";
import moment from "moment";
import ChartAssignment from "./components/ChartAssignment";

const Assignment = () => {
  const [filterValue, setFilterValue] = useState({});
  

  
 

 
  const columns = [
    {
      title: intlPlaceholder("ID"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.assignmentId || "---"}
        </span>
      ),
      key: "ID",
    },
    {
      title: intlPlaceholder("quiz.title"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.assignmentTitle || "---"}
        </span>
      ),
      key: "Title",
    },
    {
      title: intlPlaceholder("Course"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.courseTitle}</span>
      ),
      key: "Course",
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {intlPlaceholder("Assignment Type")}
        </span>
      ),
      render: (record) => (
        <span className="gx-text-capitalize gx-mr-3">
          {record.assignmentType || "---"}
        </span>
      ),
      key: "assignmentType",
    },
    {
      title: intlPlaceholder("Questions"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.noOfQuestions || "---"}
        </span>
      ),
      key: "Questions",
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {intlPlaceholder("Total Mark")}{" "}
        </span>
      ),
      render: (record) => (
        <span className="gx-text-capitalize gx-pr-3">
          {record.totalMarks || "---"}
        </span>
      ),
      key: "totalMark",
    },
    {
      title: intlPlaceholder("assignment.passMark"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.passingMarks}</span>
      ),
      key: "passMark",
    },
    
    {
      title: <span style={{ whiteSpace: "nowrap" }}> {"Attachments"}</span>,
      render: (record) => (
        <span className="gx-text-capitalize gx-link gx-text-blue">
            {record.attachFileUrl.length>0 ?( <ShowModal
            modalName={"viewAssignments_Modal"}
            buttonName="Attachment"
            record={record}
          />):"----"}
        </span>
      ),
      key: "attachFile",
    },
    {
      title: intlPlaceholder("assignment.deadline"),
      render: (record) => (
        <span className="gx-text-capitalize">{moment(record.dueDate).format('DD-MM-YYYY')}</span>
      ),
      key: "deadline",
    },
   
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {intlPlaceholder("Created By")}
        </span>
      ),
      render: (record) => (
        <span
          style={{ whiteSpace: "nowrap" }}
          className="gx-text-capitalize gx-pr-3 "
        >
          {record.createdBy || "---"}
        </span>
      ),
      key: "createdBy",
    },
    
    {
      title: intlPlaceholder("Status"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status || "---")}
        </span>
      ),
      key: "Status",
    },
   
  ];
  return (
    <Layout>
      <ChartAssignment />
      <Widget>
        <TableHeader
          filterValue={filterValue}
          setFilterValue={setFilterValue}
        />
        <AssignmentList
          apiName="getAssignmentsForStudent"
          recordName="assignmentList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
      <ViewAssignments />
    </Layout>
  );
};

export default Assignment;
