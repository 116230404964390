import { Col, Row } from "antd";
import React from "react";
import {  useSelector } from "react-redux";
import ChartCard from "components/ChartCard";
import { FaMeetup } from "react-icons/fa";
import PathGreen from "assets/images/Path38119.png";
import GraphPurple from "assets/images/Group41973.png";
import PathRed from "assets/images/Path38055.png";

const ReservationChart = () => {

  const curdProps = useSelector((state) => state.CurdR);
  const { cardRecord} = curdProps;

  return (
    <div>
      <Row className="gx-mb-2 gx-h-50">
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#EFD7FB"
            title={"reservationCard1.Title"}
            icon={<FaMeetup size={29} fill="#A212E8" />}
            count={(cardRecord && cardRecord.length && cardRecord[0].totalMeetings) || ""}
            graph={<img src={GraphPurple} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#D4F4E8"
            title={"reservationCard2.Title"}
            icon={<FaMeetup size={29} fill="#02BC77" />}
            count={(cardRecord && cardRecord.length && cardRecord[0].completedMeetings) || ""}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#FADADC"
            title={"reservationCard3.Title"}
            icon={<FaMeetup size={29} fill="#FF2366" />}
            count={(cardRecord && cardRecord.length && cardRecord[0].cancelledMeetings) || ""}
            graph={<img src={PathRed} alt="graph" />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ReservationChart;
